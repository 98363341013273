import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav, Collapse } from 'reactstrap';
import { Route, Link } from 'react-router-dom';
import { Navbar, NavbarBrand } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Login from '../../main/containers/login';

import LogoPraceLab from './../../assets/svg/icons/logo_prace-lab.svg';
import TaskIcon from './../../assets/svg/icons/task.svg';
import GuideIcon from './../../assets/svg/icons/coursebook.svg';
import { StyledNavbar, UsersUiLink, MyTicketsLink } from '../styles/styled-components/Topbar';
import { __env } from '../../envloader';
import getCurrentLang from '../utils/contextUtils';

@connect(
  state => ({
    loginStore: state.login.get('loginData'),
  }),
)
@withTranslation()
export default class TopBar extends Component {

  render() {
    const { t, i18n } = this.props;
    const lang = getCurrentLang().substring(0, 2);
    const filename = `portal-logo-${lang}.svg`;
    const PortalLogoFromIbis = `${__env.IBIS_TENANT_URL}themes/${filename}`;
    const isLogged = !!this.props.loginStore;
    return (
      <StyledNavbar
        color="white"
        className="sticky-top py-3 px-5 flex-grow-1"
        expand
      >
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center">
            <Navbar color="faded" expand>
              <NavbarBrand tag={Link} to="/">
                <div>
                  <img
                    className="logo"
                    src={PortalLogoFromIbis}
                    onError={e => e.target.src=LogoPraceLab}
                    alt={t('toolbar.logoPraceLab')}
                  />
                </div>
              </NavbarBrand>
              <NavbarBrand tag="a" href={__env.USERS_UI_URL}>
                <div className="d-flex align-items-center">
                  <UsersUiLink>{i18n.language?.startsWith('pl') ? __env.USERS_UI_NAME_PL : __env.USERS_UI_NAME_EN}</UsersUiLink>
                </div>
              </NavbarBrand>
              {isLogged && (
                <NavbarBrand tag={Link} to="/tickets">
                  <div className="d-flex align-items-center">
                    <img src={TaskIcon} alt={t('toolbar.my_tickets')}/>
                    <MyTicketsLink>{t('toolbar.my_tickets')}</MyTicketsLink>
                  </div>
                </NavbarBrand>
              )}
              <a href={__env.HANDBOOK_URL} className='navbar-brand'>
                <div className="d-flex align-items-center">
                  <img src={GuideIcon} alt={t('toolbar.users_guide')}/>
                  <MyTicketsLink>{t('toolbar.users_guide')}</MyTicketsLink>
                </div>
              </a>
            </Navbar>
          </div>
          <div className="d-flex justify-content-end">
            <Collapse navbar>
              <Nav navbar pills>
                <Route strict path="/" render={props => (
                  <Login location={props.location} loginBar={true}/>
                )}/>
              </Nav>
            </Collapse>
          </div>
        </div>
      </StyledNavbar>
    );
  }
}

TopBar.propTypes = {
  t: PropTypes.func, //HOC
  i18n: PropTypes.object,
  loginStore: PropTypes.object //HOC
};
