import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import KeyImage from '../../assets/svg/start/key.svg';
import TicketImage from '../../assets/svg/start/ticket.svg';
import EULogo from '../../assets/svg/landinng/EU.svg';
import PLLogo from '../../assets/svg/landinng/PL.svg';
import FELogo from '../../assets/svg/landinng/FE.svg';
import { CopyrightFooter, FlagLogo, HelpdeskTasks, InformationLink } from '../styles/styled-components/welcomePage';
import { __env } from '../../envloader';
import InstitutionLogo from './InstitutionLogo';

@connect(
  state => ({
    loginStore: state.login.get('loginData'),
  }),
)
@withTranslation()
export default class LandingPageFooter extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        {!this.props.loginStore &&
          <HelpdeskTasks className='helpdesk-footer'>
            <div className="main">
              <div className="center">
                <div className="welcome-footer-title">
                  <h1>{t('welcome.footer.title')}</h1>
                </div>
                <div className="block">
                  <div className="d-flex flex-row column">
                    <div>
                      <img className="picture" src={KeyImage} alt={t('welcome.footer.left.image_alt')}/>
                    </div>
                    <div className='right-txt'>
                      <h2>{t('welcome.footer.left.title')}</h2>
                      <h3>{t('welcome.footer.left.description')}</h3>
                    </div>
                  </div>
                  <div className="d-flex flex-row column">
                    <div>
                      <img className="picture" src={TicketImage} alt={t('welcome.footer.right.image_alt')}/>
                    </div>
                    <div className='right-txt'>
                      <h2>{t('welcome.footer.right.title')}</h2>
                      <h3>{t('welcome.footer.right.description')}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </HelpdeskTasks>
        }
        <CopyrightFooter>
          <div className="d-flex flex-row justify-content-between">
            <div className="p-2">
              <div className="d-flex flex-row">
                <div className="p-2">
                  <InstitutionLogo/>
                </div>
                <div className="p-2">
                  <InformationLink href={ __env.ACCESSIBILITY_PAGE_URL }>{t('welcome.footer.bottom.accessibility')}</InformationLink>
                </div>
                <div className="p-2">
                  <InformationLink href={ __env.REGULATIONS_PAGE_URL }>{t('welcome.footer.bottom.regulations')}</InformationLink>
                </div>
              </div>
            </div>
            <div className="p-2">
              <div className="d-flex flex-row-reverse">
                <div className="p-2">
                  <FlagLogo src={EULogo} alt={t('welcome.footer.logos.eu')}/>
                </div>
                <div className="p-2">
                  <FlagLogo src={PLLogo} alt={t('welcome.footer.logos.pl')}/>
                </div>
                <div className="p-2">
                  <FlagLogo src={FELogo} alt={t('welcome.footer.logos.fe')}/>
                </div>
              </div>
            </div>
          </div>
        </CopyrightFooter>
      </div>
    )
    ;
  }
}

LandingPageFooter.propTypes = {
  t: PropTypes.func, //HOC
  loginStore: PropTypes.object, //HOC
};
